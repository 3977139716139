import { produce } from "immer";
import {
} from '../constants';
// import { v4 as uuid } from 'uuid';
// enableMapSet();
export default produce((draft, payload) => {
  switch (payload.type) {
    case 'AAAA':
      // draft.set(payload.name, payload.d);

      console.log('!!!!!!', payload.name);

      draft[payload.name] = {};
      


      break;
    default:
      break;
  }
}, {});