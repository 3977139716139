import {
  WELLCOME_BUTTONS,
  MESSAGE_LOADRER,
  IS_READY
} from 'store/constants';

export const setMessageLoader = payload => ({
  type: MESSAGE_LOADRER, 
  payload
});

export const setWellcomeButtons = payload => ({
  type: WELLCOME_BUTTONS, 
  payload
});

export const setAppReady = payload => ({
  type: IS_READY, 
  payload
});