import { Manager } from 'socket.io-client';
import config from 'config';
import Auth from '@aws-amplify/auth';
import { SERVER_MESSAGE, SIGN_OUT } from 'store/constants';
import { signOut } from './auth';
import { closeNotification } from './notifications';
import { errorNotification } from 'utils/notifications'
import { 
  setMessageLoader, setWellcomeButtons,
  setAppReady
} from "./config";
import i from 'i18';
import { Hub } from '@aws-amplify/core'

const errorNotificationId = 'socketConnection';
const errorNotificationData = {
  id: errorNotificationId,
  title: i('Connecting to Hala ...'),
  description: i('In case of long connecting time, could you please check your internet connection or try to <a class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out" href="/">Reload</a> Hala.'),
  timeout: 0
};

const manager = new Manager(config.socketUrl, {
  autoConnect: false,
  secure: true,
  withCredentials: true
});

manager.on('reconnect_error', () => errorNotification(errorNotificationData));

export const socket = manager.socket("/", {
  secure: true,
  withCredentials: true,
  auth: async (cb) => {
    const session = await Auth.currentSession();  
    const idToken = session.getIdToken()
    cb({
      token: idToken.getJwtToken(),
      user: idToken.payload.sub
    });
  }
});

Hub.listen(SIGN_OUT, () => socket.disconnect())

const bindEvents = (() => {
  let isBinded = false;
  return dispatch => {
    if (isBinded) {
      return false;
    }
    isBinded = true;
    socket.on('connect', () => {
      dispatch(closeNotification(errorNotificationId));
      dispatch(setAppReady(true));
    });

    socket.on('connect_error', error => {
      const msg = error.message;
      console.error('!!!!!! connect_error:', msg)
      if (msg === 'ACCESS_DENIED' || msg === 'No current user') {
        dispatch(signOut());
      } else {
        errorNotification({
          ...errorNotificationData,
          description: msg
        })
      }
    });

    socket.on('message', payload => {
      dispatch({ type: SERVER_MESSAGE, payload });
    });
    socket.on('messageLoader', payload => {
      dispatch(setMessageLoader(payload));
    });
    socket.on('wellcomeButtons', payload => {
      dispatch(setWellcomeButtons(payload));
    });

    return true;
  }
})();

export const connect = () => (
  async dispatch => {
    try {
      socket.disconnect();
      bindEvents(dispatch);
      socket.connect();
    } catch (err) {}
  }
);

export const disconnect = () => () => socket.disconnect();