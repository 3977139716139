import React from 'react';
import AsyncSelect from 'react-select/async';
import { convertOptions } from './index';
// import API from '@aws-amplify/api';
// import { apiName, siteContentApi } from 'store/constants';
// import siteContent from 'utils/siteContentApi';
import useSelectValue from './hooks/useSelectValue';
import useAction from 'hooks/useAction'

function filter(options, inputValue) {
  let items = options.slice();
  if (inputValue === '-') {
    inputValue = '';
  }
  if (inputValue) {
    items = items.filter(i =>
      (i.label && i.label.toLowerCase().includes(inputValue.toLowerCase()))
      || (i.value && i.value.toLowerCase().includes(inputValue.toLowerCase()))
    );
  }
  if (items.length > 100) {
    items = items.slice(0, 100);
    items.push({
      value: 'Type to get more results...',
      label: 'Type to get more results...',
      isDisabled: true
    })
  }
  return items;
}

// const cache = (() => {

//   const data = {};

//   function get(id) {
//     if (!data[id]) return null;
//     if (data[id].exp < new Date().getTime()) {
//       return null;
//     }
//     return data[id].data;
//   }

//   function set(id, _data) {
//     data[id] = {
//       exp: new Date().getTime() + 5 * 60 * 1000,
//       data: _data
//     };
//   }

//   return {
//     get, set
//   }
// })();


// function useLoadOptions(props, optionsFormat) {
//   const { 
//     action, 
//     type, url,
//     api = apiName, 
//     method = 'post',
//     parameters = {} 
//   } = props;
//   const [options, setOptions] = React.useState(null);
//   const fetch = React.useCallback(async search => {
//     if (!options) {
//       let _url = url
//       if (!_url && api === apiName && action) {
//         _url = `/actions/${action}/exec?type=${type}`;
//       }
//       let param = {};
//       if (method === 'post') {
//         param = { body: parameters };
//       } else {
//         param = { params: parameters };
//       }
//       const cacheId = `${method}:${api}:${_url}:${JSON.stringify(param)}`;
//       let data = cache.get(cacheId);
//       if (!data || (Array.isArray(data) && !data.length)) {
//         try {
//           if (api === siteContentApi) {
//             data = await siteContent(method, _url, param);
//           } else {
//             data = await API[method](api, _url, param);
//           }
//           cache.set(cacheId, data);
//         } catch (err) {
//           console.error(err);
//         }
//       }
//       data = convertOptions(data, optionsFormat, type);
//       setOptions(data);
//       return filter(data, search);
//     }
//     return filter(options, search);
//   }, [options, action, optionsFormat, type, parameters, url, api, method]);
//   return {fetch, options};
// }

function RSelectRemote({
  optionsFormat, remote,
  value, ...props
}) {
  const [enabled, setEnabled] = React.useState(false)
  const onFocus = React.useCallback(() => setEnabled(true), []);
  const [options, setOptions] = React.useState([]);
  remote = React.useMemo(() => {
    return {
      path: optionsFormat?.path,
      ...remote
    }
  }, [optionsFormat, remote]);
  const {isLoading, data} = useAction(remote, {enabled});
  const {type} = remote;
  const filterOptions = React.useCallback(async search => filter(options, search), [options])
  value = useSelectValue(value, options, props.onChange, true);

  React.useEffect(() => {
    const _format = {
      ...optionsFormat
    }
    delete _format.path;
    setOptions(convertOptions(data, _format, type));
  }, [data, type, optionsFormat]);

  const canSearch = React.useMemo(() => options.length ? true : false, [options]);
  const onKeyDown = React.useCallback(e => e.preventDefault(), []);

  return (
    <AsyncSelect
      menuPlacement="auto"
      {...props}
      onFocus={onFocus}
      value={value}
      cacheOptions
      defaultOptions={options}
      onKeyDown={canSearch ? undefined : onKeyDown}
      loadOptions={canSearch ? filterOptions : undefined}
      isLoading={isLoading}
    />
  );
}

export default RSelectRemote;