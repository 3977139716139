import produce from "immer";
import {
  MESSAGE_LOADRER,
  WELLCOME_BUTTONS,
  SIGNED_OUT,
  IS_READY,
  CLIENT_MESSAGE
} from '../constants';

const initState = {
  ready: false,
  messageLoader: false,
  wellcomeButtons: [
    "Hello",
    "What are your capabilities?"
  ]
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case IS_READY:
      draft.ready = payload;
      break;
    case CLIENT_MESSAGE:
    case MESSAGE_LOADRER:
      draft.messageLoader = payload;
      break;
    case WELLCOME_BUTTONS:
      if (payload && payload.length) {
        draft.wellcomeButtons = payload;
      }
      break;
    case SIGNED_OUT:
      return initState;
    default:
      break;
  }
}, initState);