import { combineReducers } from "redux";
import layout from './layout';
import notifications from './notifications';
import filters from './filters';
import auth from './auth';
import messages from './messages';
import config from './config';

export default combineReducers({
  layout,
  notifications,
  filters,
  auth,
  messages,
  config
});