import API from '@aws-amplify/api';
import { 
  useQuery, useMutation, useQueryClient 
} from 'react-query'
import { apiName } from 'store/constants'
import { useCallback, useMemo } from 'react'

export function getFetcher({queryKey}, qs = {}) {
  const [url] = queryKey;
  return API.get(apiName, url, {
    queryStringParameters: qs
  });
}

export function postData(url, body = {}) {
  return API.post(apiName, url, {
    body
  })
}

export function deleteData(url) {
  return API.del(apiName, url);
}

const skillsUrl = '/skills';
export function useSkillsInfo(tags) {
  const qs = useMemo(() => {
    if (tags) {
      return {
        tags
      }
    }
    return undefined;
  }, [tags]);
  return useQuery([skillsUrl, qs], getFetcher);
}

export function useSaveSkillQuery(id) {
  return useCallback(data => {
    return postData(`${skillsUrl}/${id}`, data);
  }, [id]);
}

export function useSaveSkill(id) {
  const queryCache = useQueryClient();
  const query = useSaveSkillQuery(id);
  return useMutation(query, {
    onSuccess: data => {
      queryCache.getQueries().forEach(q => {
        if (q.queryKey[0] === skillsUrl) {
          queryCache.setQueryData(q.queryKey, items => items.map(i => {
            if (i._id === id) {
              return {
                ...i,
                ...data
              }
            }
            return i;
          }));
        }
      })
    }
  });
}